<template>
  <div>
    <Spinner v-show="showInProgressSection" message="Cancellation is in progress. Please wait..."/>
    <DoodleMessage class="has-text-grey-dark" :message="doodleForm.errorMessage" message-class="has-text-danger" v-show="doodleForm.errorMessage"/>
    <DoodleMessage class="has-text-grey-dark" message="Subscription Cancelled. Thanks for Using JDoodle.com Compiler APIs." message-class="has-text-danger" v-show="doodleForm.completed"/>
    <div class="has-text-centered" v-if="showMainSection">
      <div class="has-text-centered has-text-grey-dark has-text-weight-bold">Are you sure want to cancel your subscription?</div>
      <div class="api-subscription-detail">
        <div v-show="tabProperties.clientPlan=='Free'" class="notes">
          <span class="underline">Note:</span> You are on Free Plan, if you proceed, your subscription will be cancelled immediately. Read <a href="/compiler-api/faq" target="_blank">API FAQ</a> for more details
        </div>
        <div v-show="tabProperties.clientPlan!='Free'" class="notes">
          <ul>
            <li>Cancellation is effective only from the end of your current billing period. No refund will be provided.</li>
            <li>You can use this subscription till the end of your current billing cycle.</li>
            <li>you can reactivate your subscription until the end of your current billing period</li>
            <li>Read <a href="/compiler-api/docs" target="_blank">API FAQ</a> for more details</li>
          </ul>
        </div>
        <div class="refresh-button-div">
          <button class="button is-danger has-text-weight-bold" @click="cancelSubscription">Cancel Subscription</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import Spinner from '../Utils/Spinner'
import DoodleMessage from '../Utils/DoodleMessage'
import { eventBus } from '../../assets/javascript/event-bus'

export default {
  name: 'apiCancelSubscription',
  components: { DoodleMessage, Spinner },
  mixins: [formMixin],
  props: ['tabProperties'],
  methods: {
    cancelSubscription () {
      this.executeAPIWitoutValiation({
        url: '/api/compiler-api/cancelSubscription',
        successAction: (data) => {
          this.$_.delay(() => { eventBus.$emit('APIPlanChanged') }, 5000)
        },
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.API,
        jdaEvent: 'cancel-subscription',
        jdaLabel: ''
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .notes {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 0.85em;
    padding: 5px;
  }

  .refresh-button-div {
    margin-top: 40px;
  }
</style>
